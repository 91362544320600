import React from 'react'
import { useTranslation } from 'react-i18next'
import { HashLink as Link } from 'react-router-hash-link'
import { useMenuContext } from '../../../contexts/MenuContext'
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher'
import styles from './LandingMenu.module.scss'

interface ILandingMenu {
	className?: string
}

const LandingMenu: React.FC<ILandingMenu> = ({ className }) => {
	const { t } = useTranslation()
	const { open, setOpen } = useMenuContext()

	return (
		<ul className={[className, styles.menu, open ? styles.open : ''].join(' ')}>
			<li
				onClick={() => setOpen(false)}
				className={[styles.item, styles.main].join(' ')}
			>
				<Link to='/'>{t('landing.menu.main')}</Link>
			</li>

			<li onClick={() => setOpen(false)} className={styles.item}>
				<Link to='/#tech'>{t('landing.menu.tech')}</Link>
			</li>
			<li onClick={() => setOpen(false)} className={styles.item}>
				<Link to='/#partners'>{t('landing.menu.partners')}</Link>
			</li>

			<li onClick={() => setOpen(false)} className={styles.item}>
				<Link to='#contacts'>{t('landing.menu.contacts')}</Link>
			</li>
			<li onClick={() => setOpen(false)} className={styles.item}>
				<Link to='/#faq'>{t('landing.menu.faq')}</Link>
			</li>
			<LanguageSwitcher className={styles.item} />
		</ul>
	)
}

export default LandingMenu
