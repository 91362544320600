import { Suspense } from 'react'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setCurrentModal } from '../../app/reducers/modal/modal.slice'
import { ModalsTypesEnum } from '../../app/reducers/modal/modal.types'
import { useApiContext } from '../../contexts/ApiContext'
import MenuContext from '../../contexts/MenuContext'
import AccountMenu from '../AccountMenu'
import AddWallet from '../AddWallet/AddWallet'
import ChangeWallet from '../ChangeWallet/ChangeWallet'
import Modal from '../Modal/Modal'
import RemoveWallet from '../RemoveWallet/RemoveWallet'
import Replenish from '../Replenish/Replenish'
import AccountHeader from '../SAIRUS_NEW/AccountHeader'
import SocialLinks from '../SAIRUS_NEW/SocialLinks/SocialLinks'
import Transfer from '../Transfer/Transfer'
import { GlowingIcon } from '../UI/GlowingIcon'
import Withdraw from '../Withdraw/Withdraw'
import styles from './AccountWrapper.module.scss'
import { AccountWrapperProps } from './AccountWrapper.types'

export default function AccountWrapper(props: AccountWrapperProps) {
	const setSearchParams = useSearchParams()[1]
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()
	const { isAuth, isLoading } = useApiContext()

	const { t } = useTranslation()

	const { currentModal } = useAppSelector(state => state.modal)

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (!isAuth && !isLoading) window.location.replace('/login')
		if (!isAuth && !isLoading) navigate('/login')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth, isLoading])

	useEffect(() => {
		setOpen(false)
	}, [location])

	const closePaymentResult = () => {
		localStorage.removeItem('PAYMENT_AMOUNT')
		localStorage.removeItem('PAYMENT_STATUS')
		localStorage.removeItem('PAYMENT_SYSTEM')
		setSearchParams(new URLSearchParams(''))
		dispatch(setCurrentModal(null))
	}

	const getPageTitle = () => {
		switch (location.pathname) {
			case '/account':
				return t('cabinet.analytics')
			case '/account/balance':
				return t('cabinet.create-deposit')
			case '/account/history':
				return t('cabinet.operations-history')
			case '/account/team':
				return t('cabinet.my-team')
			case '/account/settings':
				return t('cabinet.account-settings')
			case '/account/materials':
				return t('cabinet.materials')
			case '/account/instructions':
				return t('cabinet.instructions')
			case '/account/messenger/':
				return t('cabinet.messenger')
			default:
				return ''
		}
	}

	return (
		<MenuContext.Provider value={{ open, setOpen }}>
			<div className={styles.wrapper}>
				<AccountHeader />
				<AccountMenu />
				<div className={styles.contentWrapper}>
					<Suspense>
						<div className={styles.content}>
							{getPageTitle().length ? (
								<div className={styles.title}>{getPageTitle()}</div>
							) : (
								<div></div>
							)}
							{props.children}
							<Outlet />
						</div>
					</Suspense>
				</div>
				<div className={styles.footer}>
					<SocialLinks className={styles.socials} />
					<div className={styles.copyright}>
						<p className='text-xs'>Copyright © 2023 SAIRUS LTD. </p>
						<p className='text-xs'>{t('all-right-reserved')}</p>
					</div>
				</div>
			</div>
			<Modal
				open={currentModal === ModalsTypesEnum.ADD}
				title={t('cabinet.create-wallet-2') || undefined}
			>
				<AddWallet />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.UPDATE}
				title={t('cabinet.update-wallet') || undefined}
			>
				<ChangeWallet />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.REMOVE}
				title={t('cabinet.remove-wallet') || undefined}
			>
				<RemoveWallet />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.REPLENISH}
				title={t('cabinet.replenish-balance-2') || undefined}
			>
				<Replenish />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.SUCCESS_PAYMENT}
				closeHandler={closePaymentResult}
				title={t('cabinet.replenish-balance-2') || undefined}
			>
				<Replenish />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.FAILED_PAYMENT}
				closeHandler={closePaymentResult}
				title={t('cabinet.replenish-balance-2') || undefined}
			>
				<Replenish />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.WITHDRAW}
				title={t('cabinet.withdraw-2') || ''}
			>
				<Withdraw onClose={() => dispatch(setCurrentModal(null))} />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.TRANSFER}
				title={t('cabinet.fund-transfer') || ''}
			>
				<Transfer onClose={() => dispatch(setCurrentModal(null))} />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.ADD}
				title={t('cabinet.create-wallet-2') || ''}
			>
				<AddWallet />
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.SUCCESSFULL_SAVE}
				title={t('modals.successfully') || ''}
			>
				<div className={styles.successfully}>
					{t('modals.successfully_save')}
				</div>
			</Modal>

			<Modal
				open={currentModal === ModalsTypesEnum.DEPOSIT_CREATED}
				title={t('calculator.success') || undefined}
			>
				<div className={styles.modalContent}>
					<p>{t('calculator.deposit-created')}</p>
					<Link
						to={'/account/history/'}
						onClick={() => dispatch(setCurrentModal(null))}
					>
						{t('cabinet.go-to-operation-history')}
						<GlowingIcon name='arrow-right' className={styles.icon} />
					</Link>
				</div>
			</Modal>
		</MenuContext.Provider>
	)
}
