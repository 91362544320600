import { Container } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApiContext } from '../../../contexts/ApiContext'
import { useMenuContext } from '../../../contexts/MenuContext'
import { MenuButton } from '../../UI/MenuButton'
import LandingMenu from '../LandingMenu/LandingMenu'
import styles from './LandingHeader.module.scss'

const LandingHeader = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const { isAuth } = useApiContext()

	const { open, setOpen } = useMenuContext()

	return (
		<Container className={styles.container}>
			<section
				className={[
					styles.wrapper,
					location.pathname === '/' || location.pathname === '/marketing'
						? styles.bg
						: '',
					open ? styles.open : '',
				].join(' ')}
			>
				<div className={styles.menu}>
					<div
						className={[styles.buttons, isAuth ? styles.auth : ''].join(' ')}
					>
						<MenuButton
							open={open}
							className={styles.menuBtn}
							onClick={() => {
								setOpen(prev => !prev)
							}}
						/>
					</div>
					<LandingMenu />
				</div>
			</section>
		</Container>
	)
}

export default LandingHeader
