import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { ModalsTypesEnum } from '../../../app/reducers/modal/modal.types'
import MenuContext from '../../../contexts/MenuContext'
import Modal from '../../Modal'
import LandingHeader from '../LandingHeader/LandingHeader'
import styles from './LandingWrapper.module.scss'

const LandingWrapper = () => {
	const [open, setOpen] = useState(false)

	const { currentModal } = useAppSelector(state => state.modal)

	const { t } = useTranslation()
	const youtubeSrc = t('landing.about.video')

	return (
		<div>
			<MenuContext.Provider value={{ open, setOpen }}>
				<LandingHeader />
				<Suspense>
					<Outlet />
				</Suspense>
				<Modal open={currentModal === ModalsTypesEnum.VIDEO} title='Sairus'>
					<div className={styles.modalContent}>
						{currentModal === ModalsTypesEnum.VIDEO && (
							<iframe
								width='560'
								height='315'
								src={youtubeSrc ? youtubeSrc : ''}
								title='YouTube video player'
								frameBorder='10'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
								allowFullScreen
							></iframe>
						)}
					</div>
				</Modal>
				<Modal
					open={currentModal === ModalsTypesEnum.CONF}
					title={'Confidentiality and Risks'}
				>
					<Confidential />
				</Modal>
				<Modal
					open={currentModal === ModalsTypesEnum.TECHNICAL_WORKS}
					title={t('tech_works.title') || ''}
				>
					<div className={styles.tech_works}>
						<p>{t('tech_works.p1')}</p>
						<p>{t('tech_works.p2')}</p>
						<p>{t('tech_works.p3')}</p>
					</div>
				</Modal>
			</MenuContext.Provider>
		</div>
	)
}

const Confidential = () => {
	return (
		<div className={styles.confidential}>
			<div>
				<h3>Confidentiality</h3>
				<p>
					SAIRUS believes that every person should feel safe. This is one of the
					main rules our platform is based on. Your personal data including your
					password and login is kept known only to you since we undertake not
					transfer information about our users to third parties
				</p>
				<p>
					We use encryption systems to protect users' personal data and provide
					server protection.
				</p>
				<p>
					In turn, we ask our users to comply with the following security
					measures:
				</p>
				<ul>
					<li>
						Never share your account data, password, or login with anyone;
					</li>
					<li>
						Ignore suspect letters demanding to provide your personal data: in
						case of force majeure contact our technical support and our manager
						will help you;
					</li>
					<li>
						If you need to restore your login, use the standard form “Forgot
						your password?”.
					</li>
				</ul>
				<p>
					We waive responsibility if data got leaked in result of your own
					actions. Therefore, we recommend you save and keep your data on a
					secure medium inaccessible to others.
				</p>
			</div>
			<div>
				<h3>Risks</h3>
				<p>
					There are no risk-free economic strategies. On its part, SAIRUS
					guarantees the following:
				</p>
				<ul>
					<li>Risk mitigation to a minimum level;</li>
					<li>User support;</li>
					<li>Hefty dividends from p2p arbitration and bonus program.</li>
				</ul>
				<p>
					Any type of investment is not always worth taking risk. Therefore, to
					reduce your personal fear of losing capital, invest the amount of
					funds that cannot affect your personal well-being.
				</p>
				<p>
					Our sector – a patented product – is created with a high level of
					security, which always remains stable. It is not affected by the
					political situation, economic fluctuation, and crises.
				</p>
				<p>
					If your knowledge is not enough to invest on your own, then try and
					get an independent advice of a financial expert. We are ready to
					provide any additional information upon your request.
				</p>
			</div>
		</div>
	)
}

export default LandingWrapper
