import { FC } from 'react'
import styles from './Button.module.scss'
import { ButtonProps } from './Button.props'

const Button: FC<ButtonProps> = ({
	label,
	bold,
	className,
	color,
	disabled,
	icon,
	onClick,
	style,
	isNeededStar = true,
}) => {
	const buttonClass = (): string => {
		const output = [styles.button]
		switch (color) {
			case 'cian':
				output.push(styles.button_cian)
				break
			case 'magenta':
				output.push(styles.button_magenta)
				break
			case 'purple':
				output.push(styles.button_purple)
				break
		}
		if (className) output.push(className)
		return output.join(' ')
	}

	return (
		<button
			className={buttonClass()}
			onClick={onClick}
			style={{ ...style }}
			disabled={disabled}
		>
			{isNeededStar && <div className={styles.button__star}></div>}
			{icon && <img src={icon} alt={label} />}
			{label}
		</button>
	)
}

export default Button
