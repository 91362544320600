import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface INewsItem {
	published: string
	imageUrl: string
	title: string
	content: string
}

interface INewsState {
	news: INewsItem[]
}

const initialState: INewsState = {
	news: [
		{
			published: '10/29/2023',
			content:
				'<p>SAIRUS p2p trades automatically, and you can monitor all the processes of trading operations online in your personal account.</p><a href="https://sairus.net/p2p/">https://sairus.net/p2p/</a><a href="https://t.me/SairusEnglish">https://t.me/SairusEnglish</a>',
			imageUrl: '/news/news.jpg',
			title: 'SAIRUS - P2P TRADING STATISTICS',
		},
		{
			published: '11/5/2023',
			content:
				'<p>The SAIRUS system is fully automated. Artificial intelligence allows the system to independently use more 30 proven and tested market strategies.</p><p>SAIRUS p2p is able to act independently, without human intervention, imitating the thinking of analysts, as well as constantly improve itself.</p><p>SAIRUS p2p works 24/7 on any market conditions, without excitement and emotional involvement in the process, thereby minimizing the mistakes of the notorious human factor.</p>',
			imageUrl: '/news/news2.jpg',
			title: 'Sairus p2p statistics from October 30 to November 5.',
		},
		{
			published: '11/12/2023',
			content:
				'<p></p><a href="https://sairus.net/p2p/">https://sairus.net/p2p/</a><a href="https://t.me/SairusEnglish">https://t.me/SairusEnglish</a>',
			imageUrl: '../../../../public/news/news_03_10.jpg',
			title: 'SAIRUS - P2P TRADING STATISTICS. November 6 - 12',
		},
		{
			published: '10/03/2025',
			content:
				"<p>Dear friends, we are glad to inform you that from 03/10/2025 to 04/10/2025 we are holding a contest for the best video feedback about SAIRUS. All participants of the contest receive $10 each to their personal account balance. 1st place gets $100, 2nd place $50, 3rd place $30. Terms of participation - you must be a registered user on the company's official website</p><a href='https://sairus.net'>https://sairus.net</a><p>you must have a valid deposit, the video must be posted on</p><a href='https://www.youtube.com'>https://www.youtube.com </a><p>where our website will be demonstrated. Send the link to the video review by email</p><a href='mailto:sairusltd@gmail.com'>sairusltd@gmail.com</a>",
			imageUrl: '/news/news3.jpg',
			title: 'Competition for the best video review',
		},
	],
}

const newsSlice = createSlice({
	name: 'referrals',
	initialState,
	reducers: {
		setNews: (state, action: PayloadAction<INewsItem[]>) => {
			state.news = [...action.payload]
		},
	},
})

export const { setNews } = newsSlice.actions
export default newsSlice.reducer
