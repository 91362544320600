import { useTranslation } from 'react-i18next'
import { CircleButton } from '../../UI/CircleButton'
import styles from './SocialLinks.module.scss'

interface Props {
	className?: string
}

const SocialLinks = (props: Props) => {
	const { t } = useTranslation()
	const handleOpenLinkTelegram = () => {
		const telegramLink = t('landing.footer.links.telegram')
		if (typeof telegramLink === 'string' && telegramLink.length > 0) {
			window.open(telegramLink, '_blank')
		} else {
			window.open('https://t.me/SairusEnglish', '_blank')
		}
	}

	return (
		<div className={[styles.wrapper, props.className].join(' ')}>
			<CircleButton
				iconName='twitter'
				dotStyle={{ backgroundColor: 'transparent' }}
				onClick={() => window.open('https://x.com/SairusLtd', '_blank')}
			/>
			<CircleButton
				iconName='instagram'
				dotStyle={{ backgroundColor: 'transparent' }}
				onClick={() =>
					window.open('https://www.instagram.com/sairusofficial', '_blank')
				}
			/>
			<CircleButton
				iconName='telegram'
				dotStyle={{ backgroundColor: 'transparent' }}
				onClick={handleOpenLinkTelegram}
			/>
			<CircleButton
				iconName='youtube'
				dotStyle={{ backgroundColor: 'transparent' }}
				onClick={() =>
					window.open('https://www.youtube.com/@SairusLTD', '_blank')
				}
			/>
		</div>
	)
}

export default SocialLinks
