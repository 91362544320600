import Avatar from '../assets/avatar.svg'
import { IUser } from '../types/User'

const useLocalUser = (user?: IUser) => {
	const avatar = user?.avatar
		? user.avatar.includes('/')
			? user.avatar
			: `${window.location.origin}/logo/${user.avatar}`
		: Avatar
	const userName =
		user?.firstName && user.lastName
			? `${user.firstName} ${user.lastName}`
			: user?.login
	return { userName, avatar }
}

export default useLocalUser
