import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setCurrentModal } from '../../app/reducers/modal/modal.slice'
import { ModalsTypesEnum } from '../../app/reducers/modal/modal.types'
import { useApiContext } from '../../contexts/ApiContext'
import Modal from '../Modal/Modal'
import Container from '../SAIRUS_NEW/Container/Container'
import SocialLinks from '../SAIRUS_NEW/SocialLinks/SocialLinks'
import { GlowingIcon } from '../UI/GlowingIcon'
import styles from './AuthWrapper.module.scss'
import { AuthWrapperProps } from './AuthWrapper.types'

export default function AuthWrapper(props: AuthWrapperProps) {
	const navigate = useNavigate()
	let [searchParams, setSearchParams] = useSearchParams()
	const { isAuth, isLoading, response } = useApiContext()

	const { t } = useTranslation()

	const { currentModal } = useAppSelector(state => state.modal)

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (isAuth && !isLoading) navigate('/account')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth, isLoading])

	useEffect(() => {
		const ref = searchParams.get('ref')
		if (ref !== null) {
			localStorage.setItem('ref', ref)
			response(`/activity/link?login=${ref}`, 'POST')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	return (
		<div
			className={[
				styles.wrapper,
				props.type === 'login' ? styles.login : styles.registration,
			].join(' ')}
		>
			<div className={styles.background}>
				<Container>
					<div className={styles.body}>
						<div className={styles.title}>{props.title}</div>
						<Link to='/' className={styles.homeLink}>
							<GlowingIcon name='arrow-right' className={styles.icon} />
							<span>{t('auth.return')}</span>
						</Link>
						{props.children}
						<SocialLinks className={styles.footer__social} />
					</div>
				</Container>
			</div>
			<Modal
				open={currentModal === ModalsTypesEnum.SUCCESSFULL_REGISTRATION}
				title={t('auth.done') || undefined}
			>
				<div className={styles.modalContent}>
					<div>{t('auth.registration-successful-text')}</div>
					<div>{t('auth.registration-activation-mail')}</div>
				</div>
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.SUCCESSFULL_ACTIVATION}
				title={t('auth.done') || undefined}
				closeHandler={() => {
					dispatch(setCurrentModal(null))
					setSearchParams('')
				}}
			>
				<div className={styles.modalContent}>
					<div>{t('auth.activation-successful-text')}</div>
				</div>
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.FAILED_ACTIVATION}
				title={t('auth.activation-failed-title') || undefined}
				closeHandler={() => {
					dispatch(setCurrentModal(null))
					setSearchParams('')
				}}
			>
				<div className={styles.modalContent}>
					<div>{t('auth.activation-failed-text')}</div>
				</div>
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.SUCCESSFULL_SEND_ACTIVATE_LINK}
				title={t('modals.successfully') || undefined}
			>
				<div className={styles.modalContent}>
					<div>{t('auth.registration-activation-mail')}</div>
				</div>
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.SUCCESSFULL_SEND_INSTRUCTION}
				title={t('modals.successfully') || undefined}
			>
				<div className={styles.modalContent}>
					<div>{t('modals.send-instructions')}</div>
				</div>
			</Modal>
			<Modal
				open={currentModal === ModalsTypesEnum.SUCCESSFULL_SEND_NEW_PASSWORD}
				title={t('modals.successfully') || undefined}
			>
				<div className={styles.modalContent}>
					<div>{t('auth.resend-password')}</div>
				</div>
			</Modal>
		</div>
	)
}
