import { Link } from 'react-router-dom'
import Logo from '../../../assets/circle_logo.png'
import { useMenuContext } from '../../../contexts/MenuContext'
import { MenuButton } from '../../UI/MenuButton'
import { MessengerButton } from '../../UI/MessengerButton'
import AccountHeaderMenu from '../AccountHeaderMenu'
import styles from './AccountHeader.module.scss'

const AccountHeader = () => {
	const { open, setOpen } = useMenuContext()

	return (
		<header className={styles.accountHeader}>
			<div
				className={[styles.logoContainer, open ? styles.menuOpen : ''].join(
					' '
				)}
			>
				<Link className={styles.headerLogo} to='/'>
					<img src={Logo} alt='' />
				</Link>
				<div className={styles.menuButtons}>
					<MessengerButton />
					<MenuButton
						open={open}
						onClick={() => {
							setOpen(prev => !prev)
						}}
					/>
				</div>
			</div>
			<AccountHeaderMenu />
		</header>
	)
}

export default AccountHeader
